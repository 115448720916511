import React from 'react';
import LeftColumn from './base';
import messages from '../../../data/messages/en';

export default (props) => (
  <LeftColumn
    {...props}
    locale='en'
    messages={messages}
  >
  </LeftColumn>
);
