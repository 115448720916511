import React from "react"

class RightColumn extends React.Component {

  render () {
    return (
      <div className="col-11 col-sm-11 col-md-4 col-lg-4">  
        {this.props.children}
      </div>
    )
  }

}

export default RightColumn;
