import React from "react"
import { Link } from "gatsby"
import { StaticQuery, useStaticQuery, graphql } from "gatsby"
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'


class Header extends React.Component {

  switchLanguage() {
    const newLocale = ('es' === this.props.locale)? 'en': 'es';

    var pathToReplace = this.props.Location.pathname ;

    if (typeof pathToReplace === `undefined`) {
      pathToReplace = '/en/'
    }


    if (this.props.Location.pathname === `/${this.props.locale}`) {
      pathToReplace = `/${this.props.locale}/`;
    }


    var newPath = pathToReplace.replace(`/${this.props.locale}/`, `/${newLocale}/`);
    if (newPath === '/') {
        newPath = '/es/';
    }
    return (
      <Link className="entries-link" to={newPath}>
        {newLocale}
      </Link>
    )
  }

  content(data) {
    return (
      <header >
        <Navbar bg="light"  expand="md" >
          <Navbar.Brand className="navbar-brand" href={`/${this.props.locale}`}>
            <img src={data.logo.childImageSharp.fixed.src} className="logo mx-auto d-block img-fluid "  />
          </Navbar.Brand>
           <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse >
          <Nav className="ml-auto row justify-content-center">
            <Link  className="contact-link float-right" to={`/${this.props.locale}/contact`}> {this.props.messages.letsTalk} </Link>
          </Nav>
        </Navbar.Collapse>

        </Navbar>

        <div className="container">
         <p className="title card-test text-justify title">

         <span className="float-right" >
          {this.switchLanguage()}
         </span>
         </p>
        </div>
      </header>

     )

  }

  build = () => {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            logo: file(relativePath: {eq: "components/layouts/img/logo.png"}) {
              childImageSharp {
                fixed(width: 100, height: 100) {
                  base64
                  src
                  width
                  height
                }
              }
            }
          }

        `}

        render={
          data => {
            return this.content(data)
          }
        }
      />)

  }


  render() {
    return this.build();
  }
}

export default Header;
