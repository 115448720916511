import React from "react"
import { Link } from "gatsby"

class PostSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return(
      <div className="card mb-5 ">
        <div className="card-body">
          {this.props.children}

          <div className="mt-3 mb-3 col-6 col-sm-4 col-md-4 col-lg-3 ml-auto">
            <Link className="btn btn-primary mx-auto" to={this.props.slug} >
              {this.props.messages.seeMore}
            </Link>
            </div>

        </div>
      </div>
    )
  }


}

export default PostSummary
