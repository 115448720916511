module.exports = {
  selectLanguage: 'Español',
  title: `Manuel Franco`,
  subTitle: `Blockchain Consultant`,
  description: `Profesional website`,
  author: `agrohelpers`,
  seeMore: 'See More...',
  blogUrl: 'https://blog.backendhelpers.co',
  googleSiteVerification: '5wkCCvMC9CUD5XXW6WPtTWCZiTQG8GgnMz0m9VBf4KU' ,
  letsTalk: "Let's Talk",
  cookiesAlert: {
    title: 'This Website uses Cookies',
    subTitle: 'Your privacy is very important. I use cookies to track your behaviour and provide a better experience',
    ok: 'Accept',
    reject: 'Exit',
  },
  postIndex: {
    title: 'Popular Entries',
    posts: [
      {
        year: 2019,
        entries: [
          {
            title: 'Building Bitcoin Core from Source Code',
            slug: '/en/2019/docker-alpine-bitcoin/',
          },
          {
            title: 'Introduction to Terraform',
            slug: '/en/2019/terraform-intro/'
          },
          {
            title: 'AWS EC2 Using Python',
            slug: '/en/2019/aws-ec2-python-intro/'
          }

        ]
      },
      {
        year: 2018,
        entries: [
          {
            title: 'Introduction to Google Cloud',
            slug: '/en/2018/google-cloud-intro/'
          },
          {
            title: 'Introduction to AWS SQS',
            slug: '/en/2018/aws-sqs-intro/'
          },
          {
            title: 'Introduction to AWS EC2',
            slug: '/en/2018/aws-ec2-intro/'
          }
        ]
      },
      {
        year: 2017,
        entries: [
          {
            title: 'Introduction to Bitcoin Core Wallet',
            slug: '/en/2017/introduction-bitcoin-wallet'
          },
          {
            title: 'K-nearest Neighbours Algorithm',
            slug: '/en/2017/k-nearest-neighbours-algorithm-knn-R'
          },
          {
            title: 'Apache Spark Resilient Distributed Datasets (RDD)',
            slug: '/en/2017/spark-rdd-intro'
          },

        ]
      },

    ]
  }
};
