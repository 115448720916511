
import React from "react"
import { Link } from "gatsby"

import { Button, Collapse, ListGroup } from 'react-bootstrap'


class PostIndex extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open2019: true,
      open2018: false,
      open2017: false,
      open2016: false,
      open2015: false,
    };
  }

  renderYear(data, idx) {
    const updateState = (year) => {
      var values = {
        open2019: false,
        open2018: false,
        open2017: false,
        open2016: false,
        open2015: false,
      };

      switch (year) {
        case 2019:
          values.open2019 = true;
          break;
        case 2018:
          values.open2018 = true;
          break;
        case 2017:
          values.open2017 = true;
          break;
        case 2016:
          values.open2016 = true;
          break;
        case 2015:
          values.open2015 = true;
          break;
      }
      this.setState(values);
    }


    const renderEntries = (item) => {
      const url = this.props.messages.blogUrl;
      return (
        <ListGroup.Item as="li" key={`li-entry-${item.slug}`} className="li-entries">
          <a className="entries-link" href={`${url}${item.slug}index.html` } key={item.slug}>  {item.title} </a>
        </ListGroup.Item>
      )
    }


    return (

      <div key={`item-container-${data.year}`} >
        <div className="card-header" key={`year-${data.year}`}>
          <Button className="btn btn-link"   variant="light" onClick={() => { updateState(data.year) }} >
            {data.year}
          </Button>


        </div>
        <Collapse in={this.state[`open${data.year}`]} className="card-body" >
          <ListGroup as="ul" className="text-center p-2" >
            <>
            {data.entries.map(renderEntries)}
            </>
          </ListGroup>
        </Collapse>
     </div>
    )

  }


  render() {
    return (
      <section>
        <div className="card">
          <h1 className="text-center entries-title mt-1">{this.props.messages.postIndex.title}</h1>
          <h2 className="mb-0 text-center">
            {this.props.messages.postIndex.posts.map(this.renderYear, this)}
          </h2>
        </div>
      </section>
    )
  }

}


export default PostIndex
