import React from 'react';
import Blog from './base';
import messages from '../../data/messages/en';

export default (props) => (
  <Blog
    {...props}
    locale='en'
    messages={messages}
  >
    {props.children}
  </Blog>
);
