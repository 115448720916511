import React from "react"


class LeftColumn extends React.Component {

  constructor(props) {
    super(props);
  }


  render () {
    return (
      <div className="col-11 col-sm-11 col-md-8 col-lg-8">
        {this.props.children}
      </div>
    )
  }

}

export default LeftColumn;
