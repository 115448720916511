import React from "react"

import { IntlProvider } from 'react-intl';
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer'
import CookiesAlert from './cookies-alert'

import './css/bootstrap.css';
import './css/bootstrap-grid.css';
import './css/bootstrap-reboot.css';
import './css/style.css';
require("prismjs/themes/prism-okaidia.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")


class Layout extends React.Component {

  constructor(props) {
    super(props);

    const loc = typeof window !== `undefined` ? window.location: '/en';
    this.state = {location: loc};
  }


  render() {
    return (
      <IntlProvider
        locale={this.props.locale}
        messages={this.props.messages}
      >

      <Helmet
        title={this.props.messages.title}
        titleTemplate={`%s | ${this.props.messages.subTitle}`}
        meta={[
          {
            name: 'og:title',
            content: this.props.messages.title
          },
          {
            name: `description`,
            content: this.props.messages.description,
          },
          {
            name: `og:description`,
            content: this.props.messages.description,
          },
          {
            property: `og:type`,
            content: this.props.messages.description,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: this.props.messages.author,
          },
          {
            name: `twitter:title`,
            content: this.props.messages.title,
          },
          {
            name: `twitter:description`,
            content: this.props.messages.subTitle,
          },
          {
            name: `google-site-verification`,
            content: this.props.messages.googleSiteVerification,
          },
        ]}
      />
      <CookiesAlert  {...this.props} storageKey="mfranco.backendhelpers.co/privacy" />
      <div className="body container">
        <Header
            {...this.props}
            Location={this.state.location}
        />

        {this.props.children}
        <Footer />
      </div>



    </IntlProvider>

    );
  }
}


export default Layout
