import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

export default function Author (props) {

  const build = (content) => {
    return (

    <StaticQuery
    query={graphql`

    query {

      logo: file(relativePath: {eq: "components/blog/author/logo-manu.png"}) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            base64
            src
            width
            height
          }
        }
      }


    bc_logo: file(relativePath: {eq: "pages/2019/docker-alpine-bitcoin/BC_Logo_.png"}) {
            childImageSharp {
              fixed(width: 100, height: 100) {
                base64
                src
                width
                height
              }
            }
          }



    }






    `}

      render={
        data => {
          return content(data);
        }
      }
    />)


  }

  const content = (data) => {
    return (
      <div  className="card mb-4">
        <div className="card-body">
          <a className="entries-link" href={props.url} >
            <h1 className="text-center"> {props.title} </h1>
            <h2 className="text-center entries-title"> {props.subTitle}</h2>
          </a>
          <p className="text-justify p-2 ">
            {props.description}
          </p>
          <div className="text-center" >
          <Link className="btn btn-success " to={`/${props.lang}/contact`}> {props.available} </Link>
          </div>
        </div>
      </div>


    )
  }

  return build(content);
}
