import React from "react"
import Layout from "../layouts/base"

class Blog extends React.Component {

  render () {

    return (
      <Layout {...this.props}>
        <section>
          <div className="row justify-content-center mt-5">

            {this.props.children}

          </div>
        </section>
      </Layout>
    );
  }

}

export default Blog
