import React from "react"

import { StaticQuery, useStaticQuery, graphql } from "gatsby"

export default (props) => {
  return (
    <>

      <StaticQuery
        query={graphql`
          query {


            logo: file(relativePath: {eq: "components/layouts/img/logo.png"}) {
              childImageSharp {
                fixed(width: 42, height: 42) {
                  base64
                  src
                  width
                  height
                }
              }
            }

            linkedin: file(relativePath: {eq: "components/layouts/img/linkedin.png"}) {
              childImageSharp {
                fixed(width: 42, height: 42) {
                  base64
                  src
                  width
                  height
                }
              }
            }

            twitter: file(relativePath: {eq: "components/layouts/img/twitter.png"}) {
              childImageSharp {
                fixed(width: 42, height: 42) {
                  base64
                  src
                  width
                  height
                }
              }
            }

            github: file(relativePath: {eq: "components/layouts/img/github.png"}) {
              childImageSharp {
                fixed(width: 42, height: 42) {
                  base64
                  src
                  width
                  height
                }
              }
            }


          }

        `}

        render={
          data => {
            return ( 
              <footer className="container footer mt-4">
                <div className="d-flex flex-column align-items-center">
                  <div className="p-1">
                    <div className="d-flex align-items-end">
                        <div className="p-2">
                            <a href="https://www.linkedin.com/in/mfran" > <img src={data.linkedin.childImageSharp.fixed.src} alt="Linkedin" className="img-fluid"/> </a>
                        </div>
                        <div className="p-2">
                            <a href="https://twitter.com/agrohelpers" > <img src={data.twitter.childImageSharp.fixed.src} alt="twitter" className="img-fluid"/> </a>
                        </div>
                        <div className="p-2">
                            <a href="https://github.com/mfranco" > <img src={data.github.childImageSharp.fixed.src} alt="github" className="img-fluid"/> </a>
                        </div>
                    </div>
                </div>
                <div className="container justify-content-end text-right my-4">
                    <a href="https://www.nahamad.com" className="by" >
                        Designed by Nadya Hamad
                    </a>
                </div>
            </div>
              </footer>
            )

          }
        }
      />
    </>
  )
}
