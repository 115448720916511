import Modal from 'react-bootstrap/Modal'
import React, { useState } from "react"
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'


class CookiesAlert extends React.Component {

  constructor(props, context) {
    super(props, context);

    var show = true;
    const key = props.storageKey;

    if (typeof(Storage) !== "undefined") {
      if (sessionStorage.getItem(key) !== "undefined" && sessionStorage.getItem(key) !== null ) {
        show = false;
      }

    }


    this.state = {
      show: show,
      key: key
    };

  }


  handleClose() {
    if (typeof(Storage) !== "undefined") {
      sessionStorage.setItem(this.state.key, true);

    }
    this.setState({show: false});
  };

  render(){

    return (
      <Alert show={this.state.show} variant="warning" className="fixed-top" >

        <Alert.Heading className="text-center">
             {this.props.messages.cookiesAlert.title}
        </Alert.Heading>
        <p className="text-center">
         {this.props.messages.cookiesAlert.subTitle}
        </p>

        <div >
          <div className="d-flex justify-content-end"> 
            <a className="btn btn-danger mx-auto" href="https://duckduckgo.com/spread" > {this.props.messages.cookiesAlert.reject}</a>

          <Button className="btn btn-secondary mx-auto" onClick={this.handleClose.bind(this)}>
              {this.props.messages.cookiesAlert.ok}
         </Button>

           </div>
        </div>
     </Alert>
    )
  }


}

export default CookiesAlert
